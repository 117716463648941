<template>
  <div class="images">
    <transition name="menu-popover">
      <div v-if="lightboxImage" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <h2 class="downloadImage">
          <a :href="lightboxImage" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </h2>
        <img :src="lightboxImage" @click="closeLightbox()" />
      </div>
    </transition>

    <section data-v-5a90ec03 class="py-5 bg8 bs-shadow">
      <div data-v-5a90ec03 class="container">
        <input
          class="top-search bs-large"
          type="text"
          placeholder="Search for images..."
          v-model="searchValue"
          @keyup.enter="searchQuery"
        />
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-lg-3 d-none d-lg-block">
          <div class="card-block bs-shadow" style="margin-top: 15px">
            <h5>Markets</h5>
            <ul class="flexwrap">
              <li v-for="market in markets" :key="market.title" class="mktBtns">
                <button
                  class="btn-primary"
                  :class="{ active: market.search == search }"
                  @click="changeMarket(market.search)"
                >
                  {{ market.title }}
                </button>
              </li>
            </ul>
          </div>

          <div class="card-block bs-shadow">
            <h5>Products</h5>
            <ul class="flexwrap">
              <li
                v-for="product in products"
                :key="product.title"
                class="mktBtns"
              >
                <button
                  class="btn-primary"
                  :class="{ active: product.search == search }"
                  @click="changeMarket(product.search)"
                >
                  {{ product.title }}
                </button>

                <!--
            <button @click="changeMarket(product.search)" class="btn-primary">{{product.title}}</button>
                -->
                <!--
            <button @click="searchCat(product.search)" class="btn-primary">{{product.title}}</button>
                -->
              </li>
            </ul>
          </div>
        </div>

        <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12 col-lg-9">
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />

              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !media.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-9 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-9">
          <div
            class="banner d-flex align-items-center p-3 my-3 rounded bs-shadow"
          >
            <div class="d-flex number-showing">
              <p class="mb0">{{ this.media.length + " Results" }}</p>
            </div>

            <div>
              <div class="nav-btns">
                <button v-if="this.offsetVal == 0" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>
                <button v-else @click="offsetValMinus()">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-if="this.media.length < 100" disabled>
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
                <button v-else @click="offsetValPlus()">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              v-for="el in media"
              :key="el.external_id"
              class="col-md-4 col col-sm-6 col-xs-12 col-12"
            >
              <div class="card bs-shadow br4">
                <div
                  class="docwrap"
                  :style="{
                    backgroundImage: 'url(' + el.thumbnails['600px'].url + ')',
                  }"
                >
                  <!-- <img :src="el.thumbnails['600px'].url" /> -->
                </div>
                <div class="card-name">
                  <p class="word-wrap">
                    <!--
            <span v-if="el.file_properties['format_type'] == 'image'">
<i class="material-icons">photo</i>
            </span>
            <span v-else>
<i class="material-icons">warning</i>
            </span>
                    -->
                    {{ el.filename }}
                  </p>
                  <span
                    class="button-wrap"
                    v-if="el.file_properties['format_type'] == 'image'"
                  >
                    <!--
            <button v-on:click="addImage(el.embeds['600pixeljpeg'].url)">Add Image</button>
          <button v-on:click="addSelectedImage(el.embeds['600pixeljpeg'].url)">Preview Image</button>


        };'
                    -->

                    <a
                      class="first links"
                      v-on:click="showLightbox(el.thumbnails['600px'].url)"
                    >
                      <i
                        class="material-icons"
                        data-toogle="tooltip"
                        title="Preview this image"
                        >visibility</i
                      >
                    </a>

                    <a
                      class="links mr5"
                      v-clipboard="el.embeds['original'].share"
                      v-on:click="copyLink"
                    >
                      <i class="material-icons">file_copy</i>
                    </a>

                    <a
                      class="links mr5"
                      :href="`mailto:?subject=&body=${el.embeds['original'].share}`"
                    >
                      <i class="material-icons">send</i>
                    </a>

                    <a
                      class="links"
                      :href="el.embeds['original'].url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        data-v-a2d59bc8
                        aria-hidden="true"
                        class="fa fa-download"
                        data-toogle="tooltip"
                        title="Download this image"
                      ></i>
                    </a>

                    <!--
        <a class="links" v-on:click="addImageToPresentation(el.thumbnails['600px'].url)"><i class="material-icons" data-toogle="tooltip" title="Add this image to presentation">add_to_photos</i></a>
        
        </span>
                    -->
                  </span>
                </div>
              </div>
              <!-- card -->
            </div>
          </div>
          <!-- bottom pagination -->
          <div
            v-if="media.length >= 4"
            class="banner d-flex align-items-center p-3 mb1r rounded bs-shadow"
          >
            <div class="d-flex number-showing">
              <p class="mb0">{{ this.media.length + " Results" }}</p>
            </div>

            <div>
              <div class="nav-btns">
                <button v-if="this.offsetVal == 0" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>
                <button v-else @click="offsetValMinus()">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-if="this.media.length < 100" disabled>
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
                <button v-else @click="offsetValPlus()">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end bottom pagination -->
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/a59d39c4db9c288752c13e9c1196c9cf",
  },
};

export default {
  name: "Images",
  components: {
    AtomSpinner,
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      offsetVal: 0,
      search: "",
      searchValue: "",
      media: [],
      loading: true,
      imagesInPresentation: [],
      selectedImage: "",
      selectedVid: null,
      fileType: "JPEG",
      lightboxImage: "",
      images: [
        { id: 1, name: "http://www.google.com/image1" },
        { id: 2, name: "ttp://www.google.com/image2" },
        { id: 3, name: "ttp://www.google.com/image3" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "images");

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items))
      //console.log('ran');
      //console.log(this.search);
      //this.loading = false;
    },

    copyLink: function () {
      this.$noty.success("Successfully copied link to clipboard!");
    },

    copyData(link) {
      this.makeData();
      this.$clipboard(this.invite_code);
      alert("Copied to clipboard");
    },

    addImageToPresentation(name) {
      console.log("adding image to presentation");
      let image = {
        thumb: name,
        large: "large image",
      };
      this.$store.commit("addToImages", image);
      this.$noty.success("Successfully added image to presentation!");
      //this.$noty.error("Oooops! Something went wrong");
    },

    offsetValMinus() {
      this.offsetVal -= 100;
      this.loading = true;

      if (this.searchValue) {
        console.log("search value is set");
        return axios
          .get(
            `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
            auth
          )
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
          });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    offsetValPlus() {
      this.offsetVal += 100;
      this.loading = true;

      if (this.searchValue) {
        console.log("search value is set");
        return axios
          .get(
            `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
            auth
          )
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
          });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    changeMarket(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.searchValue = "";
      //this.$store.commit('changeMarket', name);
      console.log(name);
      this.search = name;

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    searchQuery(search) {
      this.search = "";
      this.offsetVal = 0;
      this.loading = true;
      return axios
        .get(
          `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
          auth
        )
        .then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
    },

    showLightbox(media) {
      console.log(media);
      this.lightboxImage = media;
    },

    closeLightbox() {
      this.lightboxImage = "";
    },

    isActiveButton: function (text) {
      return text;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    shouldButtonBeActive: function () {
      return this.markets.filter((el) => this.isActiveButton(el)).length === 0;
    },

    myOffsetVal() {
      return this.offsetVal.valueOf();
    },

    endPointCompute() {
      return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AMarket%2F${this.search}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
    },

    cart() {
      return this.$store.state.cart;
    },

    currentMarket() {
      return this.$store.state.market;
    },

    markets() {
      return this.$store.state.markets;
    },

    products() {
      return this.$store.state.products;
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.docwrap {
  background-repeat: no-repeat;
  background-position: center center;
}
.docwrap img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.card {
  margin-bottom: 15px;
}
.word-wrap {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadImage a {
  color: #fff;
  transition: color 0.5s ease;
}
.downloadImage a:hover {
  color: #42b983;
}
.mr5 {
  margin-right: 5px;
}
</style>
